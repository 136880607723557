import styled from 'styled-components'


export const Container = styled.div`
  width: ${props => props.$inModal ? '980px' : '500px'};
  height: ${props => props.$inModal ? '546px' : '278px'};
  @media(max-width: 1199px) {
    width: ${props => props.$inModal ? '980px' : '450px'};
    height: ${props => props.$inModal ? '546px' : '230px'};
  }
  @media(max-width: 991px) {
    width: ${props => props.$inModal ? '320px' : '290px'};
    height: ${props => props.$inModal ? '160px' : '145px'};
  }
`

export const Inner = styled.div`
  outline: none;
  height: 100%;
  img {
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }
`

export const Arrow = styled.img`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: ${props => props.$left ? '18px' : 'unset'};
  right: ${props => props.$right ? '18px' : 'unset'};
  width: 19px;
  height: 39px;
  z-index: 1;
  cursor: pointer;
`