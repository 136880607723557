import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'

import { Container, Arrow } from './styledComponents'
import { Close } from '../../forms/styledComponents'

import withModal from '../../forms/withModal'


const StyledClose = styled(Close)`
  top: -32px;
  right: -32px;
  &::after, &::before {
    background-color: #fff;
  } 
  @media(max-width: 991px) {
    right: 0;
  }    
`

const StyledContainer = styled(Container)`
  box-shadow: 0px 0px 20px 3px #000;
  border-radius: 4px;
  .slick-dots {
    bottom: 30px;
  }
  .slick-dots li {
    margin: 0 25px;
  }
  .slick-dots li button:before {
    font-size: 55px;
  }
`

const StyledArrow = styled(Arrow)`
  left: ${props => props.$left ? '-30px' : 'unset'};
  right: ${props => props.$right ? '-30px' : 'unset'};
  @media(max-width: 991px) {
    left: ${props => props.$left ? '0px' : 'unset'};
    right: ${props => props.$right ? '0px' : 'unset'};
  }    
`

const PrevArrow = ({ onClick }) => (
  <StyledArrow src='/static/images/arrow-left.png' $left onClick={onClick} />
)

const NextArrow = ({ onClick }) => (
  <StyledArrow src='/static/images/arrow-right.png' $right onClick={onClick} />
)

const ImageSlider = ({ activeSlide, data, close }) => {

  const settings = {
    initialSlide: activeSlide,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 600,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  }

  return (
      <StyledContainer $inModal>
        <Slider {...settings}>
          {data}
        </Slider>
        <StyledClose size='26px' onClick={close}/>
      </StyledContainer>
  )
}

export default withModal(ImageSlider)