import React, { Component } from 'react';
import styled from 'styled-components';

const Line = styled.div`
  z-index: 2;
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: #dfdfdf;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledScrollingLine = styled.div`
  z-index: 2;
  position: relative;
  top: ${(props) => props.top || 0}px;
  width: 1px;
  height: 140px;
  background: ${(props) =>
    props.direction === 'bottom' ? 'linear-gradient(#dfdfdf, #ff5826)' : 'linear-gradient(#ff5826, #dfdfdf)'};
  transition: 0.5s;
  transition-timing-function: ease-out;
  @media (max-width: 991px) {
    margin-top: 15px;
  }
  &::after {
    content: '';
    position: absolute;
    transition: 0.4s;
    bottom: ${(props) => (props.direction === 'bottom' ? '0px' : '131px')};
    left: -4px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #ff5826;
  }
`;

export default class ScrollingLine extends Component {
  constructor() {
    super();
    this.state = {
      heightWithoutScrollBar: 0,
      lineHeight: 0,
      topPosition: 0,
      direction: 'bottom',
      lastScrollPos: 0,
    };
    this.timer = null;

    this.setLineRef = (elem) => {
      this.lineRef = elem;
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.setInitialHeights);
    this.setInitialHeights();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.setInitialHeights);
    clearTimeout(this.timer);
  }

  setInitialHeights = () => {
    this.setState({
      heightWithoutScrollBar: this.getHeightWithoutScrollBar(),
      lineHeight: this.lineRef.clientHeight - 140,
    });
  };

  getHeightWithoutScrollBar = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    const heightWithoutScrollBar = scrollHeight - document.body.clientHeight;
    return heightWithoutScrollBar;
  };

  handleScroll = () => {
    if (!this.timer) {
      this.setScrollingParams();

      this.timer = setTimeout(this.clearTimer, 200);
    }
  };

  clearTimer = () => {
    this.setScrollingParams();

    this.timer = null;
  };

  setScrollingParams = () => {
    const topPosition = this.getTopPosition();
    if (this.state.lastScrollPos > window.scrollY) {
      this.setState({
        topPosition: topPosition,
        direction: 'top',
        lastScrollPos: window.scrollY,
      });
    } else {
      this.setState({
        topPosition: topPosition,
        direction: 'bottom',
        lastScrollPos: window.scrollY,
      });
    }
  };

  getTopPosition = () => {
    const heightWithoutScrollBar = this.state.heightWithoutScrollBar;
    const lineHeight = this.state.lineHeight;

    const offsetInPercents = 100 / (heightWithoutScrollBar / window.scrollY);
    const topPosition = (lineHeight / 100) * offsetInPercents;
    // console.log('🚀 ~ topPosition:', topPosition);

    return topPosition;
  };

  render() {
    const top = 0;
    const direction = 'top';

    return (
      <Line ref={this.setLineRef}>
        <StyledScrollingLine top={top} direction={direction} />
      </Line>
    );
  }
}
