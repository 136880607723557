import React from 'react'
import styled from 'styled-components'

import Description from './Description' 
import Slider from './Slider' 


const Container = styled.li`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  @media(max-width: 991px) {
    display: block;
    margin-bottom: 50px;
  }
  &:last-child {
    margin-bottom: 240px;
    @media(max-width: 991px) {
      margin-bottom: 50px;
    }
  }
  
`
const Year = styled.div`
  border-radius: 50%;
  z-index: 3;
  height: 34px;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-top: 122px;
  font-family: montserrat-bold;
  color: #2d2d2d;
  font-size: 14px;
  @media(max-width: 991px) {
    margin-top: 15px;
  }
`

const Project = ({ data }) => {
  return (
    <Container>
      <Description data={data.description}/>
      <Year>{data.year} г.</Year>
      <Slider data={data.photos}/>
    </Container>
  )
}

export default Project