import React, { Component } from 'react'
import Slider from 'react-slick'

import { Container, Inner, Arrow } from './styledComponents'
import ModalSlider from './ModalSlider'


const PrevArrow = ({ onClick }) => (
  <Arrow src='/static/images/arrow-left.png' $left onClick={onClick} />
)

const NextArrow = ({ onClick }) => (
  <Arrow src='/static/images/arrow-right.png' $right onClick={onClick} />
)

class ImageSlider extends Component {
  constructor() {
    super()
    this.state = {
      activeSlide: 0,
      showModal: false
    }
  }

  getImageArray = () => {
    const images = this.props.data.split('\n')

    return images.map((image, index) => {
      return <Inner
        key={index}
        onClick={() => this.setState({ showModal: true })}
        dangerouslySetInnerHTML={{ __html: image }}
      />
    })
  }

  render() {
    const settings = {
      arrows: true,
      dots: false,
      infinite: true,
      draggable: false,
      speed: 600,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />
    }
    return (
      <>
        <Container>
          <Slider {...settings}>
            {this.getImageArray()}
          </Slider>
        </Container>
        {this.state.showModal ?
          <ModalSlider
            activeSlide={this.state.activeSlide}
            data={this.getImageArray()}
            close={() => this.setState({ showModal: false })}
          />
          : null
        }
      </>
    )
  }
}

export default ImageSlider