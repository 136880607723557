import React from 'react'
import styled from 'styled-components'


const Inner = styled.div`
  width: 500px;
  line-height: 1.53;
  color: #000;
  font-size: 15px;
  @media(max-width: 1199px) {
    width: 450px;
    font-size: 13px;
  }
  @media(max-width: 991px) {
    width: 290px;
    font-size: 13px;
  }

  h2 {
    font-family: montserrat-bold;
    line-height: 1.33;
    color: #2d2d2d;
    font-size: 24px;
    margin-bottom: 30px;
    @media(max-width: 1199px) {
      font-size: 18px;
    }
    @media(max-width: 991px) {
      margin-bottom: 15px;
    }
  }
  .description {
    margin-bottom: 20px;
  }
  .customer {
    font-size: 16px;
    font-weight: bolder;
    color: #000;
    @media(max-width: 1199px) {
      font-size: 14px;
    }
  }
`

const Description = ({ data }) => {
  return (
    <Inner dangerouslySetInnerHTML={{ __html: data }} />
  )
}

export default Description