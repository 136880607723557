import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layouts';
import { Container } from '../components/styledComponents';
import Project from '../components/pages/projects';
import ScrollingLine from '../components/pages/projects/ScrollingLine';

const Title = styled.h1`
  margin-bottom: 60px;
`;
const ProjectWrapper = styled.div`
  position: relative;
  & > ul > li:nth-child(even) {
    flex-direction: row-reverse;
  }
`;

export default class Projects extends Component {

  getPojects = () => {
    const edges = this.props.data.allMarkdownRemark ? this.props.data.allMarkdownRemark.edges : [];

    let projectsMap = [];

    edges.forEach((edge, index, array) => {
      if (edge.node.frontmatter.main) {
        let mainSection = edge.node.frontmatter.section;

        let project = { year: edge.node.frontmatter.year };
        array.forEach((elem) => {
          let node = elem.node;
          if (!node.frontmatter.main) {
            if (mainSection === node.frontmatter.section) {
              project[node.frontmatter.block] = node.html;
            }
          }
        });
        projectsMap.push(project);
      }
    });

    const projectList = projectsMap.map((project, index) => {
      return <Project key={index} data={project} />;
    });

    return <ul>{projectList}</ul>;
  };

  render() {
    return (
      <Layout>
        <Container>
          <Title>Проекты</Title>
          <ProjectWrapper>
            <ScrollingLine />
            {this.getPojects()}
          </ProjectWrapper>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query getProjectData {
    allMarkdownRemark(
      filter: { frontmatter: { page: { eq: "projects" } } }
      sort: { fields: [frontmatter___year], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            block
            main
            index
            year
          }
          html
        }
      }
    }
  }
`;
